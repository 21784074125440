<template>
  <LoadingAnimation />
  <div class="appContainer">
    <router-view />
  </div>
</template>

<script>
'use strict';

// Imports.
import router from '/src/router';

// Component imports.

// Set up the default application component.

import LoadingAnimation from '/src/components/ui/LoadingAnimation.vue';
export default {
  components: {
    LoadingAnimation
  }
};
</script>

<style scoped lang="scss">
body {
  background-color: black;
}
.appContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
