<template>
  <p>Congratulations frens.</p>
  <iframe ref="iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSdZwQ2GLLAyyX-JZFvsW8iEIOaQYLwp0h2jUCiv2s8Jk_mw5Q/viewform?embedded=true&widget=false&headers=false&chrome=false" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</template>
<script>
import { ref } from 'vue';
export default {
  setup() {
    const iframe = ref(null);
    console.log(iframe.value);

    // iframe.value.classList.add('my-class');

    return { iframe };
  }
};
</script>
<style scoped lang="scss">
p {
  color: #21dc3a;
  text-shadow: 0 0 5px #21dc3a;
}
iframe {
  width: 100%;
  height: 800px;
}

.hide {
  display: none;
}
</style>
