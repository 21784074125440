'use strict';

// Component imports.
import { createApp } from 'vue';
// Style imports.
import './assets/style/main.scss';
import 'animate.css';
import 'vue-skeletor/dist/vue-skeletor.css';

// Application imports.
import App from './App.vue';
import router from './router';

// Create our application with required dependencies.
createApp(App).use(router).mount('#app');

// store.$app = app;
